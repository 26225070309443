export const User = {
  Auth: '/user/auth',
  GoogleAuth: '/user/googleauth',
  FacebookAuth: '/user/facebookauth',
  RefreshToken: '/user/refresh',
  RegisterSysAdmin: '/user/registersysadmin',
  RegisterDepotEmployee: '/user/registerdepotemployee',
  RegisterDepotDriver: '/user/registerdepotdriver',
  ForgotPasswordSendCode: '/user/forgotpwdsendcode',
  ForgotPasswordConfirmCode: '/user/forgotpwdconfirm',
  RevokeAccess: '/user/revoke',
  ConfirmEmail: '/user/confirmemail',
  ResendEmailConfirmationCode: '/user/resendconfirm',
  UpdateUser: '/user/update',
  DecryptConfirmationToken: '/user/decryptconfirmationtoken',
  List: '/user/list',
  SysAdminList: '/user/sysadminlist',
  DepotEmployeeList: '/user/depotemployeelist',
  ChangeDepotEmployeePassword: '/user/changedepotemployeepassword',
  Remove: '/user/remove',
  Disable: '/user/disable',
  Enable: '/user/enable',
  GetById: '/user/get',
};

export const Client = {
  GetWithdrawMinimum: '/client/getwithdrawminimum',
  GetCharities: '/client/getcharities',
  GetFundraisers: '/client/getfundraisers',
  GetCharityBalance: '/client/getcharitybalance',
  AuthorizeCharity: '/client/authorizecharity',
  UnauthorizeCharity: '/client/unauthorizecharity',
  GetCharityDetails: '/client/getcharitydetails',
  EnableClient: '/client/enable',
  DisableClient: '/client/disable',
  GetClients: '/client/getclients',
  GetClientsWithoutRecurringPickup: '/client/getclientswithoutrecurringpickup',
  UpdateWallet: '/client/updatewallet',
  SendPayout: '/client/sendpayout',
  UpdateClient: '/client/update',
  UpdateCharity: '/client/updatecharity',
  CreateCharity: '/client/createcharity',
  GetEtransfers: '/client/getetransfers',
  GenerateXML: '/client/generatexml',
  ConfirmPayment: '/client/confirmpayment',
  RollbackPayment: '/client/rollbackpayment',
  GetPaidETransfers: '/client/getpaidetransfers',
  GetClientsByDepot: '/client/getclientsbydepot',
  UpdatePerc: '/client/updatepercentagefee',
  GetWalletTransactions: '/client/getwallettransactions',
  GetWalletTransactionsByDate: '/client/getwallettransactionsbydate',
  GetEtransfersByDate: '/client/getetransfersbydate',
  GetOrderHistory: '/client/getcustomerorderhistory',
  GetETransferHistory: '/client/getcustomeretransferhistory',
  GetDonationHistory: '/client/getcustomerdonationhistory',
  GetRecurringPickups: '/client/getrecurringpickups',
  GetRecurringPickupsByDepot: '/client/getrecurringpickupsbydepot',
  GetRecurrenceSettings: '/client/getrecurrencesettings',
  EnableRecurringPickup: '/client/enablerecurringpickup',
  DisableRecurringPickup: '/client/disablerecurringpickup',
  GetByUserId: '/client/getbyuserid',
}

export const ErrorLog = {
  List: '/errorlog/list',
  PostalCodeList: "/depot/postalcodelist",
  SelectList: "/depot/selectlist",
  SavePostalCode: "/depot/savepostalcode",
};

export const Depot = {
  RegisterDepot: '/depot/register',
  PostalCodeList: '/depot/postalcodelist',
  SavePostalCode: '/depot/savepostalcode',
  SelectList: '/depot/selectlist',
  DepotList: '/depot/list',
  DisableDepot: '/depot/disable',
  EnableDepot: '/depot/enable',
  GetById: '/depot/getbyid',
  Update: '/depot/update',
  GetAllCoverages: '/depot/getallcoverages',
  GetAllCoverageAreas: '/depot/getallcoverageareas',
  SetCoverage: '/depot/setcoverage',
  SetDepotCoverage: '/depot/setdepotcoverage',
  GetDepotInCoverage: '/depot/getdepotincoverage',
  GetDepotBalance: '/depot/getdepotbalance',
  GetPromocodes: '/depot/getpromocodes',
  CreatePromocode: '/depot/createpromocode',
  RemovePromocode: '/depot/removepromocode',
  GetBottleDrives: '/depot/getbottledrives',
  GetBottleDrive: '/depot/getbottledrive',
  CreateBottleDrive: '/depot/createbottledrive',
  EnablePromocode: '/depot/enablepromocode',
  DisablePromocode: '/depot/disablepromocode',
  SwitchAutoApplyPromocode: '/depot/switchAutoApplyPromocode',
  UpdatePromocode: '/depot/updatepromocode',
  UpdateBudget: '/depot/updatebudget',
  GetTransactionsByDepot: '/depot/gettransactionsbydepot',
  GetSysDepotTransactions: '/depot/getsysdepottransactions',
  GetPromocode: '/depot/getpromocode',
  GetSysDepotAddedTransactions: '/depot/getsysdepotaddedtransactions',
  GetBottleDrivesByDepot: '/depot/getbottledrivesbydepot',
  SendReferralVerificationCode: 'depot/sendreferralverificationcode',
  VerifyReferralVerificationCode: 'depot/verifyreferralverificationcode'
};

export const EmailConfig = {
  GetConfiguration: '/emailconfig/getconfiguration',
  Update: '/emailconfig/update',
};

export const Config = {
  GetDaysLimit: '/config/getdayslimit',
  SetDaysLimit: '/config/setdayslimit',
  SetWithdrawMinimum: '/config/setwithdrawminimum',
  GetPercentageLimit: '/config/getpercentagelimit',
  SetPercentageLimit: '/config/setpercentagelimit',
  GetThresholdInfo: '/config/getthresholdinfo',
  SetThresholdInfo: '/config/setthresholdinfo',

  GetETransferConfig: '/config/getetransferconfig',
  SetETransferConfig: '/config/setetransferconfig',

  GetReferralConfig: '/config/getreferralconfig',
  SetReferralConfig: '/config/setreferralconfig',

  

  ResetETransferConfig: '/config/resetetransferconfig',

  GetDepotFee: '/config/getdepotfee',
  SetDepotFee: '/config/setdepotfee',
  GetEtransferFee: '/config/getetransferfee',
  SetEtransferFee: '/config/setetransferfee',

  GetPopups: '/config/getpopups',
  CreatePopup: '/config/createpopup',
  RemovePopup: '/config/removepopup',
  EnablePopup: '/config/enablepopup',
  DisablePopup: '/config/disablepopup',
  GetPopup: '/config/getpopup',
  UpdatePopup: '/config/updatepopup',
}

export const Schedule = {
  GetLimits: "/schedule/getlimits",
  UpdateLimits: "/schedule/updatelimits",
  GetAllDriversSchedules: "/schedule/getalldriversschedules",
  UpdateSchedules: "/schedule/updateschedules",
  GetDayScheduleCalendar: "/schedule/getdayschedulecalendar",
  SaveDaySchedule: "/schedule/savedayschedule",
  DeleteDaySchedule: "/schedule/deletedayschedule",


  SaveSchedule: "/schedule/saveschedule",
  DeleteSchedule: "/schedule/deleteschedule",
};

export const Order = {
  GetOrdersByDepot: '/order/getordersbydepot',
  UpdateOrderDepot: '/order/updateOrderDepot',
  UpdatePickupOrder: '/order/updateOrder',
  CancelOrder: '/order/cancelorder',
  GetById: '/order/getbyid',
  GetAll: '/order/getall',
  GetByOrderId: '/order/getbyorderid',
  GetByDate: '/order/getbydate',
  GetCountingOrders: '/order/getcountingorders',
  UploadPhoto: '/order/uploadphoto',
  GetCompletedOrdersByDate: '/order/getcompletedordersbydate',
  CreateOrder: '/order/createorder',
};
